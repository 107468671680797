var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-5"},[_c('b-row',{staticClass:"pending-requests justify-content-center mx-0 px-2"},_vm._l((_vm.ownRequests),function(request){return _c('SerialRequestCard',{key:request.created,staticClass:"mx-3 mb-4",attrs:{"request":request,"inEdit":_vm.requestsInEdit},on:{"delete":_vm.requestDeleted,"error":_vm.requestError,"changeInEdit":_vm.changeInEdit}})}),1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"form-section-label"},[_vm._v(" Proof of Print "),_c('b-link',{attrs:{"id":"proof-of-print-hint"}},[_vm._v(" ? ")]),_c('b-tooltip',{attrs:{"target":"proof-of-print-hint","triggers":"hover"}},[_vm._v(" Your \"Proof of Print\" should be a video – at least 10 seconds in length – that clearly shows your printer object, object, with the entire printer visible at one point. ")])],1),_c('div',{staticClass:"form-section"},[_c('div',{attrs:{"id":"proof-of-print-wrapper"}},[(_vm.form.useExternalVideoLink)?_c('validation-provider',{attrs:{"name":"youtubeUrl","rules":"required|validYoutubeId"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mt-2",attrs:{"id":"youtube-link-input-group","invalid-feedback":validationContext.errors[0],"description":"Enter a YouTube URL"}},[_c('b-form-input',{staticClass:"mt-2",attrs:{"id":"youtube-link-input","state":_vm.getValidationState(validationContext),"type":"text","placeholder":"https://youtube.com/watch?v=mH1pvDHrmjk"},model:{value:(_vm.form.youtubeUrl),callback:function ($$v) {_vm.$set(_vm.form, "youtubeUrl", $$v)},expression:"form.youtubeUrl"}})],1)]}}],null,true)}):_c('file-pond',{attrs:{"name":"file","class-name":"my-2","required":"","accepted-file-types":"video/*","allowReplace":"False","allowRevert":"False","label-idle":"Drag & Drop file or <span class='filepond--label-action' tabindex='0'>Browse</span>","server":{
              process: {
                url: _vm.form.presignedUrl,
                ondata: function(formData) {
                  /**
                   * file has to be last entry in FormData,
                   * put fields from presigned url first
                   **/
                  var file = formData.getAll('file')[1];
                  formData.delete('file');
                  for (var key in _vm.form.presignedUrlFields) {
                    formData.set(key, _vm.form.presignedUrlFields[key]);
                  }
                  formData.set('file', file);
                  return formData;
                },
                onerror: function() {
                  _vm.form.filePath = undefined;
                }
              }
            },"credits":"{/*sorry*/}"},on:{"initfile":_vm.handleFileInit}})],1),(_vm.form.useExternalVideoLink)?_c('b-link',{on:{"click":function($event){_vm.form.useExternalVideoLink = false}}},[_vm._v("I want to upload a file instead.")]):_c('b-link',{on:{"click":function($event){_vm.form.useExternalVideoLink = true}}},[_vm._v("Nevermind, I want to paste a YouTube link after all.")])],1),_c('div',{staticClass:"form-section-label"},[_vm._v(" Configuration ")]),_c('div',{staticClass:"form-section pb-4"},[_c('validation-provider',{attrs:{"name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"m-0",attrs:{"state":_vm.getValidationState(validationContext),"invalid-feedback":"Select a model."}},[_c('b-form-row',{staticClass:"btn-group-toggle mt-2 mx-n1",attrs:{"align-h":"between","data-toggle":"buttons"}},_vm._l((_vm.form.revisionOptions),function(options,model){return _c('b-col',{key:model,class:[
                  'btn btn-light btn-toggle mx-1 mt-1',
                  { active: _vm.form.model === model }
                ],attrs:{"tag":"label","sm":options.wideText ? 'auto' : ''}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.model),expression:"form.model"}],attrs:{"type":"radio","name":"model","autocomplete":"off","id":model,"state":_vm.getValidationState(validationContext)},domProps:{"value":model,"checked":_vm._q(_vm.form.model,model)},on:{"click":function($event){_vm.form.size = options.defaultSize},"change":function($event){return _vm.$set(_vm.form, "model", model)}}}),_vm._v(" "+_vm._s(options.label)+" ")])}),1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"revision","rules":{
            requiredIf: _vm.form.model && _vm.form.revisionOptions[_vm.form.model]
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"m-0",attrs:{"state":_vm.getValidationState(validationContext),"invalid-feedback":(_vm.form.model && _vm.form.revisionOptions[_vm.form.model].revisions
                ? 'Revision and size are'
                : 'Size is') + ' required.'}},[_c('b-form-row',{staticClass:"btn-group-toggle mt-3 mx-n1",attrs:{"align-h":"between","data-toggle":"buttons"}},[_vm._l((_vm.form.model
                  ? _vm.form.revisionOptions[_vm.form.model].revisions
                  : null),function(revision){return _c('b-col',{key:revision,class:[
                  'btn btn-light btn-toggle mx-1 mt-1',
                  { active: _vm.form.revision === revision }
                ],attrs:{"tag":"label","col":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.revision),expression:"form.revision"}],attrs:{"type":"radio","name":"revision","autocomplete":"off","id":revision,"state":_vm.getValidationState(validationContext)},domProps:{"value":revision,"checked":_vm._q(_vm.form.revision,revision)},on:{"change":function($event){return _vm.$set(_vm.form, "revision", revision)}}}),_vm._v(" "+_vm._s(revision)+" ")])}),_c('b-col',{class:[
                  'mt-sm-1',
                  {
                    'mt-3 ml-sm-1 pl-sm-0':
                      _vm.form.model && _vm.form.revisionOptions[_vm.form.model].revisions
                  }
                ],attrs:{"cols":"12","sm":_vm.form.model && _vm.form.revisionOptions[_vm.form.model].revisions
                    ? '4'
                    : '12'}},[_c('b-input-group',{attrs:{"append":"mm","state":_vm.getValidationState(
                      _vm.form.size && _vm.form.size > 0
                        ? Object.assign({}, validationContext, {valid: null})
                        : Object.assign({}, validationContext, {valid: false})
                    )}},[_c('b-form-input',{attrs:{"id":"size-input","type":"number","placeholder":"Size","state":_vm.getValidationState(
                        _vm.form.size && _vm.form.size > 0
                          ? Object.assign({}, validationContext, {valid: null})
                          : Object.assign({}, validationContext, {valid: false})
                      )},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}})],1)],1)],2)],1)]}}],null,true)})],1),_c('div',{staticClass:"form-section-label"},[_vm._v(" Additional Info ")]),_c('div',{staticClass:"form-section"},[_c('validation-provider',{attrs:{"name":"description","rules":{ max: 256 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mt-3",attrs:{"invalid-feedback":validationContext.errors[0]}},[_c('b-form-textarea',{attrs:{"placeholder":"Add a short description here","state":_vm.getValidationState(validationContext)},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]}}],null,true)}),_c('b-form-row',{staticClass:"btn-group-toggle mt-3 mx-n1",attrs:{"align-h":"between","data-toggle":"buttons"}},[_vm._l((_vm.form.hotendOptions),function(options,hotend){return _c('b-col',{key:hotend,class:[
              'btn btn-light btn-toggle mx-1 mt-1',
              { active: _vm.form.hotend === hotend }
            ],attrs:{"tag":"label","sm":"3"}},[_c('input',{attrs:{"type":"radio","id":hotend,"name":"hotend","autocomplete":"off"},domProps:{"value":hotend},on:{"click":function($event){_vm.form = Object.assign({}, _vm.form, {hotend: hotend})}}}),_vm._v(" "+_vm._s(options.label)+" ")])}),_c('b-col',{class:[
              'btn btn-light btn-toggle mx-1 mt-1',
              {
                active:
                  _vm.form.hotend !== undefined &&
                  !_vm.form.hotendOptions[_vm.form.hotend]
              }
            ],attrs:{"tag":"label"}},[_c('input',{attrs:{"type":"radio","id":"customHotend","name":"hotend","autocomplete":"off"},on:{"click":function($event){_vm.form = Object.assign({}, _vm.form, {hotend: ''})}}}),_vm._v(" Custom ")])],2),(_vm.form.hotend !== undefined && !_vm.form.hotendOptions[_vm.form.hotend])?_c('validation-provider',{attrs:{"name":"custom-electronics","rules":{
            requiredIf:
              _vm.form.hotend !== undefined && !_vm.form.hotendOptions[_vm.form.hotend],
            max: 32
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mt-2",attrs:{"state":_vm.getValidationState(validationContext),"invalid-feedback":validationContext.errors[0]}},[_c('b-form-input',{attrs:{"placeholder":"Describe","state":_vm.getValidationState(validationContext)},model:{value:(_vm.form.hotend),callback:function ($$v) {_vm.$set(_vm.form, "hotend", $$v)},expression:"form.hotend"}})],1)]}}],null,true)}):_vm._e(),_c('b-form-row',{staticClass:"btn-group-toggle mt-3 mx-n1",attrs:{"align-h":"between","data-toggle":"buttons"}},[_vm._l((_vm.form.electronicsOptions),function(options,electronics,index){return [_c('b-col',{key:electronics,class:[
                'btn btn-light btn-toggle mx-1 mt-1',
                { active: _vm.form.electronics === electronics }
              ],attrs:{"tag":"label","sm":""}},[_c('input',{attrs:{"type":"radio","id":electronics,"name":"electronics","autocomplete":"off"},domProps:{"value":electronics},on:{"click":function($event){_vm.form = Object.assign({}, _vm.form, {electronics: electronics})}}}),_vm._v(" "+_vm._s(options.label)+" ")]),(_vm.splitOptionsIntoRows(index))?_c('div',{key:index,staticClass:"w-100 mt-1"}):_vm._e()]}),_c('b-col',{class:[
              'btn btn-light btn-toggle mx-1 mt-1',
              {
                active:
                  _vm.form.electronics !== undefined &&
                  !_vm.form.electronicsOptions[_vm.form.electronics]
              }
            ],attrs:{"tag":"label"}},[_c('input',{attrs:{"type":"radio","id":"customElectronics","name":"electronics","autocomplete":"off"},on:{"click":function($event){_vm.form = Object.assign({}, _vm.form, {electronics: ''})}}}),_vm._v(" Custom ")])],2),(
            _vm.form.electronics !== undefined &&
              !_vm.form.electronicsOptions[_vm.form.electronics]
          )?_c('validation-provider',{attrs:{"name":"custom-electronics","rules":{
            requiredIf:
              _vm.form.electronics !== undefined &&
              !_vm.form.electronicsOptions[_vm.form.electronics],
            max: 32
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mt-2",attrs:{"state":_vm.getValidationState(validationContext),"invalid-feedback":validationContext.errors[0]}},[_c('b-form-input',{attrs:{"placeholder":"Describe","state":_vm.getValidationState(validationContext)},model:{value:(_vm.form.electronics),callback:function ($$v) {_vm.$set(_vm.form, "electronics", $$v)},expression:"form.electronics"}})],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"location","rules":{ max: 32 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mt-3 mb-1",attrs:{"description":"Please don't enter your full address...","state":_vm.getValidationState(validationContext),"invalid-feedback":validationContext.errors[0]}},[_c('b-form-input',{staticClass:"mt-1",attrs:{"placeholder":"Zip | City | Country","state":_vm.getValidationState(validationContext)},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1)]}}],null,true)})],1),_c('b-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('b-button',{staticClass:"px-4 mr-3",attrs:{"type":"submit","variant":"dark","disabled":_vm.form.busy}},[_vm._v(" Get Cereal ")]),_c('b-col',[_c('b-alert',{staticClass:"alert-message",attrs:{"size":"sm","variant":_vm.form.submitted ? 'success' : 'danger',"show":_vm.form.message.length > 0,"fade":""}},[_vm._v(" "+_vm._s(_vm.form.message)+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }