




































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Serial, SerialRequest } from "@/types";
import registryService from "@/services/registry.service";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  required_if as requiredIf,
  max,
  // eslint-disable-next-line @typescript-eslint/camelcase
  min_value
} from "vee-validate/dist/rules";
import SerialRequestCard from "@/components/SerialRequestCard.vue";
import {
  BForm,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRow,
  BInputGroup,
  BFormTextarea,
  BAlert
} from "bootstrap-vue";

extend("required", { ...required, message: "This field is required." });
extend("requiredIf", { ...requiredIf, message: "This field is required." });
extend("min_value", min_value);
extend("max", {
  ...max,
  message: function m(_, val2) {
    return `${val2._value_.length}/${val2.length}`;
  }
});
extend("validYoutubeId", {
  validate: async url => {
    const findIdInUrl = url.match(
      /^(?:(?:https:\/\/)?(?:www\.)?(?:youtube\.com\/)?(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*?[?&]v=)|youtu\.be\/)(?<youtubeId>[^"&?/\s]{11})(?:[^-/\w]*)$/
    );
    if (
      findIdInUrl === null ||
      !findIdInUrl.groups ||
      !findIdInUrl.groups.youtubeId
    ) {
      return "Invalid YouTube URL.";
    } else {
      return (await registryService.validateYoutubeId(
        findIdInUrl.groups.youtubeId
      ))
        ? true
        : "Video not found. Maybe it's set to private?";
    }
  }
});

const FilePond = vueFilePond(FilePondPluginFileValidateType);

@Component({
  head: {
    title: function() {
      return { inner: this.$options.name };
    }
  },
  components: {
    SerialRequestCard,
    FilePond,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRow,
    BInputGroup,
    BFormTextarea,
    BAlert
  }
})
export default class Request extends Vue {
  private ownRequests: Serial[] = [];
  private busy = false;
  private requestsInEdit = 0;
  form: {
    youtubeUrl?: string;
    filePath?: string;
    revision?: string;
    size?: number;
    hotend?: string;
    electronics?: string;
    description?: string;
    location?: string;

    useExternalVideoLink: boolean;
    presignedUrl: string;
    presignedUrlFields: object;
    model?: string;
    busy: boolean;
    submitted: boolean;
    message: string;
    revisionOptions: object;
    hotendOptions: object;
    electronicsOptions: object;
  } = {
    useExternalVideoLink: true,
    presignedUrl: "",
    presignedUrlFields: {},
    busy: false,
    submitted: false,
    message: "",

    revisionOptions: {
      V0: {
        label: "Voron V0",
        defaultSize: 120,
        revisions: ["V0.0", "V0.1", "V0.2"]
      },
      V1: {
        label: "Voron V1",
        revisions: ["V1.0", "V1.5", "V1.6", "V1.8"]
      },
      V2: {
        label: "Voron V2",
        revisions: ["V2.0", "V2.1", "V2.2", "V2.4"]
      },
      VS: {
        label: "Voron Switchwire",
        wideText: true,
        defaultSize: 250
      },
      VL: {
        label: "Voron Legacy",
        wideText: true,
        defaultSize: 230
      },
      VPX: {
        label: "Voron Phoenix",
        wideText: true,
        defaultSize: 600
      }
    },

    hotendOptions: {
      V6: {
        label: "E3D V6"
      },
      Mosquito: {
        label: "Slice Mosquito"
      },
      Dragon: {
        label: "Phaetus Dragon"
      }
    },

    electronicsOptions: {
      RAMPS: {
        label: "RAMPS"
      },
      "SKR1.3": {
        label: "SKR 1.3"
      },
      "SKR mini e3": {
        label: "SKR mini E3"
      },
      Duet: {
        label: "Duet3d Duet"
      }
    }
  };

  private getValidationState({
    dirty,
    validated,
    valid = null
  }: {
    dirty: boolean;
    validated: boolean;
    valid: boolean | null;
  }) {
    return dirty || validated ? valid : null;
  }

  private splitOptionsIntoRows(index: number): boolean {
    return (
      (index + 1) %
        (Math.floor(Object.keys(this.form.electronicsOptions).length / 2) +
          1) ===
      0
    );
  }

  private async handleFileInit(file: { filename: string; fileType: string }) {
    const { url, fields } = await registryService.getUploadUrl(file.filename);
    //content-type needs to be manually appended to the form
    fields["Content-Type"] = file.fileType;
    this.form.presignedUrl = url;
    this.form.presignedUrlFields = fields;
    this.form.filePath = fields.key;
  }

  private submitForm() {
    this.form.busy = true;
    const serialRequest: SerialRequest = {
      revision: this.form.revision || this.form.model,
      embed: this.form.useExternalVideoLink
        ? this.form.youtubeUrl
        : this.form.filePath,
      description: this.form.description,
      size: String(this.form.size),
      electronics: this.form.electronics,
      hotend: this.form.hotend,
      location: this.form.location
    } as SerialRequest;

    registryService
      .postRequest(serialRequest)
      .then(result => {
        //TODO
        console.log(result);
        this.form.submitted = true;
        this.form.message = "Your serial request has been submitted!";
        this.getOwnRequests();
      })
      .catch(error => {
        console.log(error.response);
        this.form.message = error.response.data.errorMessage;
        this.form.busy = false;
      });
  }

  private async getOwnRequests() {
    const userId = this.$store.getters["authentication/userId"];
    this.ownRequests = await registryService.getUserRequests(userId);
  }

  private requestDeleted(serialRequest: Serial) {
    this.ownRequests = this.ownRequests.filter(
      request => request !== serialRequest
    );
  }

  private requestError(errorMessage: string) {
    console.error(errorMessage);
  }

  private changeInEdit(inEdit: number) {
    this.requestsInEdit = inEdit;
  }

  created() {
    this.getOwnRequests();
  }
}
